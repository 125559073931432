import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import "./styles.css";
import Footer from "./components/footer/Footer";
import Inicio from "./components/Inicio/Inicio";
import Proyectos from "./components/proyectos/Proyectos"
import { useTheme } from "./context/ThemeContext";

function App() {

  const { isDarkMode } = useTheme() 

  return (
    <div className={`App ${isDarkMode ? "dark" : ""}`}>
      <Navbar />

      <Routes>
        <Route path="/" element={<Navigate to="/Inicio" />} />
        
        <Route path={"/Inicio"} element={<Inicio />} />

        <Route path={"/Proyectos"} element={<Proyectos />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
