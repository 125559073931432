import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import foto from "../../assets/webp/profile-pic.webp";
import fotoLego from "../../assets/webp/lego.webp";
import resume from "../../assets/documents/Resume.pdf";

function BentoPrincipal() {
  const { isDarkMode } = useTheme();
  const [fotoPerfil, setFotoPerfil] = useState(true);

  const cambiarFoto = () => {
    setFotoPerfil((estado) => !estado);
  };

  const bio = `Ing. Civil en Informática apasionado por la tecnología, el diseño y
  las metodologías ágiles desde Concepción, Chile con grandes
  motivaciones en conocer gente con intereses similares y hacer
  comunidad.`;

  const disponible = false;
  const disponibleText = (
    <p>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="#02bc7d"
        className="bi bi-exclamation-circle-fill"
        viewBox="0 0 16 16"
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
      </svg>
      Disponible para trabajar
    </p>
  );

  return (
    <div className={`BentoPrincipal ${isDarkMode ? "dark" : ""}`}>
      <div className="FotoPerfil">
        <input
          type="image"
          src={`${fotoPerfil ? foto : fotoLego} `}
          alt="foto-de-perfil"
          onClick={cambiarFoto}
          width="250"
          height="250"
        ></input>
        {disponible? disponibleText : ""}
      </div>

      <div className="BentoPrincipalSaludo">
        <h2>
          Hola, soy Rodolfo{" "}
          <a
            type="button"
            href="https://www.linkedin.com/in/rodolfocuevas/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              style={{ backgroundColor: "#FFFF", borderRadius: "5px" }}
              className="bi bi-linkedin"
              viewBox="0 0 16 16"
            >
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
            </svg>
          </a>
        </h2>

        <p>{bio}</p>
        <div className="BentoPrincipalLinks">
          <a
            type="button"
            className="btn"
            href={resume}
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#ffff"
              className="bi bi-file-earmark-arrow-down"
              viewBox="0 0 16 16"
            >
              <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z" />
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
            </svg>
            Curriculum
          </a>
        </div>
      </div>
    </div>
  );
}

export default BentoPrincipal;
