import React from 'react'
import Cards from './Cards'
import { useTheme } from "../../context/ThemeContext";

function Proyectos() {



  const {isDarkMode} = useTheme()

  return (
    <div className={`proyectos ${isDarkMode ? "dark" : ""}`}>
        <h2>Proyectos</h2>
        <Cards />
    </div>
  )
}

export default Proyectos