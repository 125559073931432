import React from "react";
import { useTheme } from "../../context/ThemeContext";
import memoji from "../../assets/img/Memoji.png"

function Footer() {
  const { isDarkMode } = useTheme();

  return (
    <footer className={`footer ${isDarkMode ? "dark" : ""}`}>
      <img src={memoji} alt="memoji"></img>
      <p>Portafolio web creado con React y Sass para mostrar mi trabajo al mundo. El repositorio del mismo se encuentra en <a className={`${isDarkMode ? "dark" : ""}`} href="https://github.com/rcuevaspantoja/portafolio_2" target="_blank" rel="noreferrer">Github</a>.</p>
    </footer>
  );
}

export default Footer;
