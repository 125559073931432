import React from 'react'
import { useTheme } from '../../context/ThemeContext'
import BentoPrincipal from './BentoPrincipal'
import BentoGrid from './BentoGrid';
function Inicio() {

  const {isDarkMode } = useTheme();

  return (
    <div className={`inicio ${isDarkMode ? "dark" : ""}`}>
    
        <BentoPrincipal />
        <BentoGrid />
    </div>
  )
}

export default Inicio