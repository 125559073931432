import React from "react";
import { useTheme } from "../../context/ThemeContext";
import { CiLink } from "react-icons/ci";

function Card({ nombre, ruta, imagen, descripcion, tecnologias }) {
  const { isDarkMode } = useTheme();

  //400 x 225
  return (
    <a
      className={`card ${isDarkMode ? "dark" : ""}`}
      href={ruta}
      target="_blank"
      rel="noreferrer"
    >
      <div className="box">
        <img src={imagen} alt="screenshot proyecto" />
        <div className="overlay">
          <CiLink className="HandClick"/>
        </div>

        <div className="card-body">
          <h4 className="card-title">{nombre}</h4>
          <p className="card-text">{descripcion}</p>
          <p className="tecnologias">{tecnologias}</p>
        </div>
      </div>
    </a>
  );
}

export default Card;
