import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import EFCertificate from "../../assets/documents/EFCertificate.pdf";
import Modal from "../modal/Modal";

function BentoGrid() {
  const { isDarkMode } = useTheme();
  const [modal, setModal] = useState(false);

  const conocimientos = (
    <>
      <p>
        Actualmente me dedico al desarrollo de software en TypeScript y NextJS e
        igualmente poseo experiencia trabajando en análisis de datos y
        reportabilidad en el cargo de{" "}
        <a
          className={`${isDarkMode ? "dark" : ""}`}
          href="https://www.fieldengineer.com/skills/scada-technician"
          target="_blank"
          rel="noreferrer"
        >
          Ingeniero en SCADA
        </a>{" "}
        por más de 1 año. Cuento con amplios conocimientos en bases de datos y herramientas
        de infomática junto con experiencia como capacitador de personal en nuevas tecnologías.
      </p>
    </>
  );

  return (
    <div className={"GridContenedor"}>
      <div className={`gridItem ${isDarkMode ? "dark" : ""} Item1`}>
        <h3>Conocimientos</h3>
        {conocimientos}
      </div>

      <a
        className={`gridItem ${isDarkMode ? "dark" : ""} Item2`}
        href="https://maps.app.goo.gl/LUDVGYJybHQaRU2z5"
        target="_blank"
        rel="noreferrer"
      >
        <span>Concepcion, Chile</span>
      </a>

      <a
        className={`gridItem ${isDarkMode ? "dark" : ""} Item3`}
        href="https://github.com/rcuevaspantoja"
        target="_blank"
        rel="noreferrer"
      >
        Github
      </a>

      <a
        className={`gridItem ${isDarkMode ? "dark" : ""} Item5`}
        href={EFCertificate}
        target="_blank"
        rel="noreferrer"
      >
        <span>Ingles Conversacional C2 EFSET</span>
      </a>

      <button
        className={`gridItem ${isDarkMode ? "dark" : ""} Item4`}
        onClick={() => setModal(true)}
      >
        <span>Hablemos!</span>
      </button>

      {modal && <Modal cerrarModal={setModal} />}
    </div>
  );
}

export default BentoGrid;
