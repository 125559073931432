import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import { send } from "emailjs-com";
import 'animate.css';
function Modal({ cerrarModal }) {
  const { isDarkMode } = useTheme();

  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");

  const formulario = {
    nombre,
    email,
    body,
  };

  const onSubmit = (e) => {
    e.preventDefault();

    send("service_r9a2oy9", "template_fjlqemy", formulario, "X-NqdN3tvWYwToREk")
      .then((response) => {
        cerrarModal(false);
        console.log("SUCCESS", response.status, response.text);
        alert("Mensaje enviado, será respondido a la brevedad.");
      })
      .catch((err) => {
        cerrarModal(false);
        console.log(err);
        alert("Hubo un problema inesperado, intente nuevamente");
      });

    console.log(formulario);

  };

  return (
    <div className="modal-background">
      <div className={`animate__bounceIn modal-box ${isDarkMode ? "dark" : ""}`}>
        <button className={`btn ${isDarkMode ? "dark" : ""}`} onClick={() => cerrarModal(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </button>
        <h3>Envíame un mensaje</h3>

        <form
          className={`formulario ${isDarkMode ? "dark" : ""}`}
          onSubmit={onSubmit}
        >
          <label for="exampleFormControlInput1">Nombre</label>
          <input
            type="nombre"
            className="form-control"
            id="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required="required"
          />

          <label for="exampleFormControlInput1">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="nombre@correo.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required="required"
          />

          <label for="cuerpo">Mensaje</label>
          <textarea
            className="form-control"
            id="cuerpo"
            rows="5"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required="required"
          ></textarea>

          <button type="submit" className={`btn ${isDarkMode ? "dark" : ""}`}>
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}

export default Modal;
