import { React } from "react";
import Botonestema from "./Botonestema";
import { useTheme } from "../../context/ThemeContext";
import { NavLink } from "react-router-dom";

function Navbar() {
  const { isDarkMode } = useTheme();
  const titulo = "<Rodolfo Cuevas />";

  document.querySelectorAll("NavLink").forEach((link) => {
    link.addEventListener("click", () => {
      const navbarResponsive = document.getElementById("navbarResponsive");
      if (navbarResponsive.classList.contains("show")) {
        const navbarToggler = document.querySelector(".navbar-toggler");
        navbarToggler.click();
      }
    });
  });

  return (
    <nav className={`navbar navbar-expand-lg ${isDarkMode ? "dark" : ""}`}>
      <div className="container-fluid">
        <h1>{titulo}</h1>
        <button
          className={`navbar-toggler ${isDarkMode ? "dark" : ""}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <NavLink
              to={"/Inicio"}
              className={`nav-link ${isDarkMode ? "dark" : ""}`}
            >
              <span
                data-bs-toggle="collapse"
                data-bs-target='.navbar-collapse.show'
              >
                Inicio
              </span>

            </NavLink>

            <NavLink
              to={"/Proyectos"}
              className={`nav-link ${isDarkMode ? "dark" : ""}`}
            >
              <span
                data-bs-toggle="collapse"
                data-bs-target='.navbar-collapse.show'
              >
                Proyectos
              </span>
  
            </NavLink>
          </div>

          <Botonestema />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
